@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Roboto", sans-serif;
}

.root {
  background-color: #ffffff;
}

#footer {
  background-color: #2d2d2d;
}

.project-info {
  height: calc(100% - 184px)
}

.project-description {
  min-height: 184px;
}
